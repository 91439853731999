import { FaTwitch, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HomepageEntry } from "../../types/homepageData";
import { AdditionalPlayerData } from "../../types/player";
import playerData from "../../_data/player-data.json";

type RankingRowProps = {
    data: HomepageEntry,
    period: string,
    rank: number
}

function RankingRow({ data, period, rank }: RankingRowProps) {
    const player = data.player;

    const additionalPlayerData = new Map(Object.entries(playerData)) as AdditionalPlayerData;
    let character: string | undefined = undefined;
    if(additionalPlayerData.has(player.name)) {
        character = additionalPlayerData.get(player.name)!.character;
    }

    let tournamentName = data.event.tournamentName.split(":")[0].split("(")[0];
    if(tournamentName.includes("Midlane Melee")) {
        const number = data.event.slug.split("/")[1].split("-")[2];
        if(parseInt(number) < 62) tournamentName += " " + number;
    }
    if(tournamentName.includes("Esports Arena")) {
        tournamentName = tournamentName.split(" ").slice(0, 3).join(" ");
    }

    const date = new Date(data.event.date * 1000);
    const winPercent = player.wins * 100 / (player.wins + player.losses);

    let longName = false;
    if(player.name.length >= 9) longName = true;

    const rating = data.player.conservativeRating ? data.player.conservativeRating.toFixed(0) : "-";

    return (
        <div className="ranking-row">
            <div className="ranking-row__item ranking-row__rank">{rating !== "-" ? rank : "-"}</div>

            <div className="ranking-row__item ranking-row__avatar-container">
                <Link to={`/stats/player/${player.name.replace("?", "")}?period=${period}`}>
                    <img src={player.profileImage} className="ranking-row__avatar" alt="profile pic" />
                </Link>
            </div>

            <div className="ranking-row__item ranking-row__name">
                <Link className={`ranking-row__name--tag ranking-row__name--tag-${longName}`} to={`/stats/player/${player.name.replace("?", "")}?period=${period}`}>{player.name}</Link>
                {player.pronouns && <div className="ranking-row__name--pronouns">{player.pronouns}</div>}
                <div className="ranking-row__name--bottom">
                    {character && <img src={require(`../../_assets/stockIcons/${character}.png`)} alt="" className="ranking-row__name--icon ranking-row__name--character" />}
                    <a href={`https://twitter.com/${player.twitterUsername}`} className="social-link" target="_blank" rel="noreferrer">
                        {player.twitterUsername && <FaTwitter className="ranking-row__name--icon" />}
                    </a>
                    <a href={`https://twitch.tv/${player.twitchUsername}`} className="social-link" target="_blank" rel="noreferrer">
                        {player.twitchUsername && <FaTwitch className="ranking-row__name--icon" />}
                    </a>
                </div>
            </div>

            <div className="ranking-row__item ranking-row__rating">{rating}</div>
            <div className="ranking-row__item ranking-row__winloss">{`${player.wins} - ${player.losses}`} <span className="ranking-row__winloss--percent">{`(${winPercent.toFixed(0)}%)`}</span></div>
            <div className="ranking-row__item ranking-row__pr-events">{player.prEvents}</div>

            <div className="ranking-row__item ranking-row__last-tournament">
                <a className="ranking-row__last-tournament--text" href={`http://start.gg/${data.event.slug}`} target="_blank" rel="noreferrer">{tournamentName}</a>
                <span className="ranking-row__last-tournament--text">{`${data.placingString} of ${data.event.numEntrants}`}</span>
                <span className="ranking-row__last-tournament--text ranking-row__last-tournament--date">{date.toLocaleDateString()}</span>
            </div>
        </div>
    )
}

export default RankingRow;