import { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { useFetchPlayerH2hs, useFetchPlayerTournaments } from "../../hooks/useFetch";
import { H2hEntry } from "../../types/h2hData";
import { HomepageEntry } from "../../types/homepageData";
import CompareH2h from "./CompareH2h";
import CompareSets from "./CompareSets";
import CompareSummary from "./CompareSummary";
import CompareTournaments from "./CompareTournaments";

type CompareMainProps = {
    player1: string;
    player2: string;
    period: string;
    isMobile: boolean;
    p1Data: HomepageEntry;
    p2Data: HomepageEntry;
}
//TODO: 0-0 Game Count looks weird
function CompareMain({ player1, player2, period, isMobile, p1Data, p2Data }: CompareMainProps) {

    const { data: p1H2h, loading: loading1, error: error1 } = useFetchPlayerH2hs(player1, period ?? "");
    const { data: p1Tourneys, loading: loading2, error: error2 } = useFetchPlayerTournaments(player1, period ?? "");
    const { data: p2Tourneys, loading: loading3, error: error3 } = useFetchPlayerTournaments(player2, period ?? "");
    const [showLoad, setShowLoad] = useState(false);
    const [directH2h, setDirectH2h] = useState<H2hEntry>();

    useEffect(() => {
        setShowLoad(false);
        setTimeout(() => {
            setShowLoad(true);
        }, 1000);
    }, [period, player1, player2]);

    useEffect(() => {
        const dH2h = p1H2h?.find(entry => entry?.opponent.toLowerCase() === player2.toLowerCase());
        setDirectH2h(dH2h);
    }, [p1H2h, player2, directH2h]);

    if(showLoad && (loading1 || loading2 || loading3)) return <LoadingSpinner />
    if (error1 || error2 || error3)  return <ErrorMessage />

    return (
        <div className="compare-main">
            {directH2h?.sets && <CompareH2h h2h={directH2h} />}
            {isMobile && <CompareSummary p1Data={p1Data} p2Data={p2Data} />}
            {directH2h?.sets && <CompareSets sets={directH2h.sets.filter(set => !set.setInfo.dq).slice().reverse()} />}
            {p1Tourneys && p2Tourneys && <CompareTournaments player1={player1} player2={player2} p1Tournaments={p1Tourneys} p2Tournaments={p2Tourneys} />}
        </div>
    );
}

function ErrorMessage() {
    return (
        <div className="compare-main">
            Unable to compare those players. Try changing the PR period or selecting a new player.
        </div>
    )
}

function LoadingSpinner() {
    return (
        <div className="compare-main">
            <FadeLoader 
                width={4}
                height={20}
                margin={10}
                cssOverride={{
                    margin: "5rem auto", display: "block"
                }} 
            />
        </div>
    );
}


export default CompareMain;