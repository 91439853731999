import { HomepageEntry } from "../../types/homepageData";
import playerData from "../../_data/player-data.json";
import { AdditionalPlayerData } from "../../types/player";

type CompareSideProps = {
    data: HomepageEntry | undefined;
    side: string;
    period: string;
}

function CompareSide({ data, side, period }: CompareSideProps) {
    if (!data) return <></>;

    const additionalPlayerData = new Map(Object.entries(playerData)) as AdditionalPlayerData;

    let lastRank = "unranked";
    if(additionalPlayerData.has(data.player.name)) {
        const rank = additionalPlayerData.get(data.player.name)!.lastRank?.toString();
        lastRank = rank ? `#${rank}` :  "unranked";
    }

    const rating = data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? data.player.conservativeRating!.toFixed(0) : "N/A";
    const rank = data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? `#${data.rank}` : "unranked";

    const prWinsPresent = data.player.hasOwnProperty("prWins")

    return (
        <div className={`compare-side compare-side__${side}`}>
            <CompareSideTile period={period} heading="Current Rank" value={rank} extraTile={prWinsPresent} />
            <CompareSideTile period={period} heading="Rating" value={rating} extraTile={prWinsPresent} />
            <CompareSideTile period={period} heading="Win - Loss" value={`${data.player.wins} - ${data.player.losses}`} extraTile={prWinsPresent} />
            {prWinsPresent && <CompareSideTile period={period} heading="PR Wins" value={data.player.prWins!} extraTile={prWinsPresent} />}
            <CompareSideTile period={period} heading="Events" value={data.player.prEvents} extraTile={prWinsPresent} />
            <CompareSideTile period={period} heading={`${process.env.REACT_APP_LAST_SEASON_NAME!} Rank`} value={lastRank} extraTile={prWinsPresent} />
        </div>
    )
}

function CompareSideTile({ heading, value, period, extraTile }: { period: string; heading: string, value: string | number, extraTile: boolean }) {
    return (
        <div className={`compare-side__tile compare-side__tile--${extraTile} ${parseInt(period) >= 5 && `compare-side__tile--${heading.replace(" ", "-")}`}`}>
            <div className="compare-side__heading">{heading}</div>
            <div className="compare-side__value">{value}</div>
        </div>
    )
}

export default CompareSide;