import { H2hEntry, Set } from "../../types/h2hData";
import { FaChevronDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";

type H2hProps = {
    h2h: H2hEntry;
    player: string;
    period: string;
    rank: string;
}

function HeadToHead ({ h2h, player, period, rank }: H2hProps) {
    const [setsVisible, setSetsVisible] = useState(false);

    useEffect(() => {
        setSetsVisible(false);
    }, [player, period])
    
    if(!h2h.sets || h2h.sets.length === 0 || !h2h.sets.some((set) => !set.setInfo.dq)) return <></>;

    let sets = h2h.sets.filter((set) => !set.setInfo.dq).slice().sort((set1, set2) => new Date(set2.date).getTime() - new Date(set1.date).getTime());

    const wins = sets.filter(set => set.setInfo.won).length;
    const losses = sets.length - wins;

    const className = wins > losses ? "h2h-positive" : wins < losses ? "h2h-negative" : "h2h-even"

    const latestTournament = sets[0].tournamentName.split(":")[0];
    const latestResult = `${sets[0].setInfo.winnerName} ${sets[0].setInfo.wonGames} - ${sets[0].setInfo.lostGames}`;

    const longName = h2h.opponent.length > 9;
    const longTourney = latestTournament.length > 16;

    return (
        <div className="h2h-container" onClick={() => setSetsVisible(!setsVisible)}>
            <div className={"h2h " + className + ` h2h-${setsVisible}`}>
                <span className="h2h__rank">{rank}</span>
                <span className="h2h__opponent"><Link onClick={(e) => {e.stopPropagation()}} className={`h2h__opponent h2h__opponent--${longName}`} to={`/stats/player/${h2h.opponent.replace("?", "")}/?period=${period}`}>{h2h.opponent}</Link></span>
                <span className="h2h__record">{`${wins} - ${losses}`}</span>
                <span className={`h2h__latest h2h__latest--${longTourney}`}>{`${latestTournament} (${latestResult})`}</span>
                <FaChevronDown className={`h2h__chevron h2h__chevron-${setsVisible}`} />
            </div>
            <Collapse in={setsVisible}>
                <div className="h2h__sets">
                    {sets.map((set) => <H2hSetDetails key={set.slug + set.setInfo.round} set={set} tournament={set.tournamentName.split(":")[0]}/>)}
                </div>
            </Collapse>
        </div>
    );
}

function H2hSetDetails({ set, tournament }: { set: Set, tournament: string }) {

    return (
        <div className={`h2h-set h2h-set-${set.setInfo.won}`}>
            <span className="h2h-set__result">
                <span className="h2h-set__games">{`${set.setInfo.wonGames} - ${set.setInfo.lostGames} `}</span> 
                at&nbsp;
                <a href={`https://start.gg/${set.slug}`} className="tsum__event--link">
                    {tournament}
                </a> 
                <span className="h2h-set__date">{` ${set.date}`}</span>
            </span>
        </div>
    );
}

export default HeadToHead;