import { Autocomplete } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAutocompleteOptions } from "../../hooks/useFetch";

function DefaultPlayerHeader({ period }: { period: string }) {
    return (
        <header className="player-header">
            <div className="player-header__info">
                <div className="player-header__avatar">
                    <img
                        src="https://storage.googleapis.com/chicago_2022-2/CLM_Logo_Avatar_Placeholder.png"
                        alt="Avatar"
                        className="player-header__avatar--image"
                    />
                </div>

                <div className="player-header__text">
                    <div className="player-header__text--top">
                        <PlayerHeaderSelector
                            period={period}
                            onEnter={() => {}}
                        />
                    </div>
                    <div className="player-header__text--bottom">
                        <span className="player-header__text--name"></span>
                    </div>
                </div>
            </div>
        </header>
    );
}

export function PlayerHeaderSelector({
    period,
    onEnter
}: {
    period: string;
    onEnter: () => void;
}) {
    const navigate = useNavigate();
    const options = useAutocompleteOptions(period);
    const [value, setValue] = useState(null);
    const onChange = useCallback(
        (e: any, newValue: any) => {
            setValue(newValue);

            const onSubmit = (playerName: string) => {
                onEnter();
                navigate(
                    `/stats/player/${playerName.replace(
                        "?",
                        ""
                    )}?period=${period}`
                );
            };

            if (!newValue) return;
            if (newValue.label) onSubmit(newValue.label);
            else onSubmit(newValue);
        },
        [setValue, navigate, onEnter, period]
    );

    return (
        <div className="player-header__form">
            <Autocomplete
                options={options}
                freeSolo
                fullWidth
                size="medium"
                value={value}
                onChange={onChange}
                ListboxProps={{
                    style: {
                        maxHeight: "28rem",
                    },
                }}
                renderInput={(params) => {
                    return (
                        <div ref={params.InputProps.ref}>
                            <input
                                type="text"
                                autoFocus
                                {...params.inputProps}
                                className="player-header__input"
                                placeholder="Select a player..."
                            />
                        </div>
                    );
                }}
            />
        </div>
    );
}

export default DefaultPlayerHeader;
