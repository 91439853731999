import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  
import TopNav from './components/TopNav';
import CompareView from './views/CompareView';
import PlayerView from './views/PlayerView';
import HomeView from './views/HomeView';
import PRCandidateView from './views/PRCandidateView';

function App() {
    return (
        <Router>
            <TopNav />
            <div className="App">
                <Routes> 
                    <Route path={"/stats"} element={<HomeView />} />
                    <Route path={"/stats/player/:player"} element={<PlayerView />} />
                    <Route path={"/stats/player/"} element={<PlayerView />} />
                    <Route path={`stats/compare/:player1/:player2`} element={<CompareView />} />
                    <Route path={"/stats/h2h"} element={<PRCandidateView />} />
                </Routes>  
            </div>
        </Router>

        
       /*
       <div className="App">
            <div className="homepage-view">
                <h1>CLM Stats is down for maintenance right now. It should be back up sometime tonight, sorry for any inconvenience.</h1>
            </div>
        </div>
        */
    );
}

export default App;
