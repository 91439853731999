import { Set } from "../../types/h2hData";

type CompareSetsProps = {
    sets: Set[]
}

function CompareSets({ sets }: CompareSetsProps) {
    if(!sets || sets.length === 0) {
        return (
            <></>
        )
    }

    return (
        <div className="compare-sets">
            <div className="compare-main__header">Sets</div>
            { sets.map((set, i) => <SetBar key={i} set={set} />)}
        </div>
    )
}

function SetBar({ set }: { set: Set }) {
    return (
        <div className="compare-sets__set">
            <div className={`compare-sets__score compare-sets__score--left compare-sets__score--left-${set.setInfo.won}`}>{set.setInfo.wonGames}</div>
            <div className="compare-sets__text">
                <div className="compare-sets__tourney">
                    <span className="compare-sets__tourney--name">{set.tournamentName.split(":")[0]}</span> 
                    <span className="compare-sets__tourney--round">{set.setInfo.round}</span>
                </div>
                <div className="compare-sets__date">{set.date}</div>
            </div>
            <div className={`compare-sets__score compare-sets__score--right compare-sets__score--right-${set.setInfo.won}`}>{set.setInfo.lostGames}</div>
        </div>
    )
}

export default CompareSets;