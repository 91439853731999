import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import CompareSide from "../components/Compare/CompareSide";
import CompareSideDefault from "../components/Compare/CompareSideDefault";
import H2hTab from "../components/Player/H2hTab";
import PlayerHeader from "../components/Player/PlayerHeader";
import TournamentTab from "../components/Player/TournamentTab";
import { useFetchHomepageEntry, useFetchPlayerH2hs, useFetchPlayerTournaments } from "../hooks/useFetch";

function PlayerView() {
    const { player: playerName } = useParams();
    const [queryParams] = useSearchParams();
    const period = queryParams.get("period");
    const { data: tournamentSummaries, loading: tLoading } = useFetchPlayerTournaments(playerName ?? "", period ?? "");
    const { data: h2hData, loading: h2hLoading } = useFetchPlayerH2hs(playerName ?? "", period ?? "");  
    const { data: player } = useFetchHomepageEntry(playerName ?? "", period ?? "");
    const [activeTab, setActiveTab] = useState("h2h");
    const[showLoad, setShowLoad] = useState(false);

    useEffect(() => {
        setShowLoad(false);
        setTimeout(() => {
            setShowLoad(true);
        }, 1000);
    }, [period, activeTab, player]);

    return (
        <div className="player-view">
            <PlayerHeader player={player} period={period ?? ""} />
            <div className="player-body">
                {h2hData ? <CompareSide period={period ?? ""} data={player} side="left" /> : <CompareSideDefault period={period ?? ""} side="left" />}
                <div className="player-content">
                    <ul className="player-tabs">
                        <li className={`player-tab player-tab-${activeTab==="h2h"}`} onClick={() => setActiveTab("h2h")}>Head To Head</li>
                        <li className={`player-tab player-tab-${activeTab==="tourney"}`} onClick={() => setActiveTab("tourney")}>Tournaments</li>
                    </ul>
                    {
                    activeTab === "h2h" && (
                        (!h2hLoading || !showLoad) ? <H2hTab h2hData={h2hData} period={period ?? ""} player={playerName ?? ""} />
                        : <LoadingSpinner />
                    )}
                    {
                    activeTab === "tourney" && (
                        (!tLoading || !showLoad) ? <TournamentTab tournamentSummaries={tournamentSummaries} player={playerName ?? ""} />
                        : <LoadingSpinner />
                    )}
                </div>
            </div>
        </div>
    );
}


function LoadingSpinner() {
    return (
        <FadeLoader 
            width={4}
            height={20}
            margin={10}
            cssOverride={{
                margin: "5rem auto", display: "block"
            }} 
        />
    );
}


export default PlayerView;