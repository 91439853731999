function CompareSideDefault({ side, period } : { side: string, period: string }) {
    const extraTile = parseInt(period) >= 5;

    return (
        <div className={`compare-side compare-side__${side}`}>
            <CompareSideTile period={period} extraTile={extraTile} heading="Current Rank" />
            <CompareSideTile period={period} extraTile={extraTile} heading="Rating" />
            <CompareSideTile period={period} extraTile={extraTile} heading="Win - Loss" />
            {extraTile && <CompareSideTile period={period} extraTile={extraTile} heading="PR Wins" />}
            <CompareSideTile period={period} extraTile={extraTile} heading="Events" />
            <CompareSideTile period={period} extraTile={extraTile} heading={`${process.env.REACT_APP_LAST_SEASON_NAME!} Rank`} />
        </div>
    )
}

function CompareSideTile({ heading, period, extraTile }: { heading: string, period: string, extraTile: boolean }) {
    return (
        <div className={`compare-side__tile compare-side__tile--${extraTile} ${parseInt(period) >= 5 && `compare-side__tile--${heading.replace(" ", "-")}`}`}>
            <div className="compare-side__heading">{heading}</div>
            <div className="compare-side__value--default"></div>
        </div>
    )
}

export default CompareSideDefault;