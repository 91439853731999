import { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import useOutsideAlerter from "../hooks/useOutsideAlerter";

const dropdownText: Map<string, string> = new Map([
    ["2", "2022 Spring"],
    ["3", "2022 Summer"],
    ["4", "2022 Fall"],
    ["5", "2023 Act I"],
    ["6", "2023 Act II"],
    ["7", "2023 Act III"],
    ["8", "2024 Act I"],
    ["9", "2024 Act II"],
    ["0", "All Time"]
])

function PeriodSelector() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(false);
    const [period, setPeriod] = useState<string>();
    const ref = useRef(null);
    useOutsideAlerter(ref, handleClickOutside);

    useEffect(() => {
        if (!searchParams.get("period")) {
            setSearchParams({ period: process.env.REACT_APP_DEFAULT_PERIOD! });
        }
        setPeriod(searchParams.get("period")!);
    }, [searchParams, setSearchParams]);

    function onClick(period: string) {
        setActive(false);
        setSearchParams({ period })
    }

    function handleClickOutside() {
        setActive(false);
    }

    return (
        <div ref={ref} className="period-select__container">
            <div className={`period-select__icon period-select__icon--${active}`} 
                onClick={() => setActive(!active)}
            >
                <span className="period-select__text">{dropdownText.get(period!) ?? "Pick a Period"} <FaChevronDown className="period-select__chevron" /></span>
                {
                    active && 
                    <div className="period-select__dropdown">
                        <div className="period-select__option" onClick={() => onClick("9")}>2024 Act II</div>
                        <div className="period-select__option" onClick={() => onClick("8")}>2024 Act I</div>
                        <div className="period-select__option" onClick={() => onClick("7")}>2023 Act III</div>
                        <div className="period-select__option" onClick={() => onClick("6")}>2023 Act II</div>
                        <div className="period-select__option" onClick={() => onClick("5")}>2023 Act I</div>
                        <div className="period-select__option" onClick={() => onClick("4")}>2022 Fall</div>
                        <div className="period-select__option" onClick={() => onClick("3")}>2022 Summer</div>
                        <div className="period-select__option" onClick={() => onClick("2")}>2022 Spring</div>
                        <div className="period-select__option" onClick={() => onClick("0")}>All Time</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PeriodSelector;