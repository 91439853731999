import { TournamentData } from "../../types/tournamentData";
import TournamentSummary from "./TournamentSummary";

type TournamentProps = {
    tournamentSummaries: TournamentData | undefined;
    player: string;
}

function TournamentTab({ tournamentSummaries, player }: TournamentProps) {
    if(!player) return <div className="tournament-summaries">Select a player above to see tournament data!</div>;
    if (!tournamentSummaries) return <div className="tournament-summaries">{`No tournament data found for this season for ${player}.`}</div>;

    const tsums = tournamentSummaries.filter((tsum) => {
        return tsum.setSummaries.filter(set => !set.dq).length > 0;
    })

    if (!tsums) return <div className="tournament-summaries">{`No tournament data found for this season for ${player}.`}</div>;

    return (
        <div className="tournament-summaries">
            {tsums.map(tsum => <TournamentSummary player={player} tournament={tsum} />)}
        </div>
    );
}

export default TournamentTab;