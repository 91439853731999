import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import PlayerCompare from "../components/Compare/PlayerCompare";

/*
    TODO: 
    - Add out of region results?
    - Font sizes are messed up
*/


function CompareView() {
    const { player1, player2 } = useParams();
    const [queryParams] = useSearchParams();
    const period = queryParams.get("period")!;

    return (
        <PlayerCompare player1={player1} player2={player2} period={period} />
    );
}

export default CompareView;