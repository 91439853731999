import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { useLocalStorage } from "usehooks-ts";
import Ranking from "../components/Home/Ranking";
import { useFetchHomepage } from "../hooks/useFetch";


function HomeView() {
    const [queryParams] = useSearchParams();
    const period = queryParams.get("period") ?? process.env.REACT_APP_DEFAULT_PERIOD!;
    const { data, loading, error } = useFetchHomepage(period ?? "");
    const[showLoad, setShowLoad] = useState(false);

    useEffect(() => {
        setShowLoad(false);
        setTimeout(() => {
            setShowLoad(true);
        }, 1000);
    }, [period]);

    if((!data && !loading && showLoad) || error) return <ErrorPage />;
    if(loading && showLoad) return <LoadingPage />;

    if(!data) {
        return <div className="homepage-view"></div>
    }

    return (
        <div className="homepage-view">
            <Ranking period={period!} data={data} />
        </div>
    );
}

function LoadingPage() {
    return (
        <div className="homepage-view">
            <FadeLoader 
                width={4}
                height={20}
                margin={10}
                cssOverride={{
                    margin: "10rem auto", display: "block"
                }} 
            />
        </div>
    )
}

function ErrorPage() {
    return (
        <div className="homepage-view">
            <div className="homepage-error">
                Failed to load CLM Stats data. Try again later.
            </div>
        </div>
    )
}


export default HomeView;