import { useFetchHomepageEntry } from "../../hooks/useFetch";
import { useIsMobile } from "../../hooks/useIsMobile";
import CompareHeader from "./CompareHeader";
import CompareMain from "./CompareMain";
import CompareSide from "./CompareSide";
import CompareSideDefault from "./CompareSideDefault";

type PlayerCompareProps = {
    player1: string | undefined;
    player2: string | undefined;
    period: string;
}

function PlayerCompare({ player1, player2, period }: PlayerCompareProps) {
    let { data: p1Data, error: p1Error, correctPeriod: p1Correct } = useFetchHomepageEntry(player1 ?? "", period);
    let { data: p2Data, error: p2Error, correctPeriod: p2Correct } = useFetchHomepageEntry(player2 ?? "", period);
    const isMobile = useIsMobile();

    return (
        <div className="compare-container">
            <CompareHeader 
                p1Data={p1Data} 
                p2Data={p2Data} 
                period={period} 
            />
            <div className="compare-body">
                {!isMobile && ((p1Data && p1Correct && !p1Error) ? 
                    <CompareSide period={period} data={p1Data} side="left" /> 
                    : <CompareSideDefault period={period} side="left" />
                )}
                {(p1Data && p2Data && p1Correct && p2Correct) ? 
                    <CompareMain player1={player1!} player2={player2!} period={period} isMobile={isMobile} p1Data={p1Data} p2Data={p2Data} />
                    :   <div className="compare-main">Select two valid players above.</div>
                }
                {!isMobile && ((p2Data && p2Correct && !p2Error) ? 
                    <CompareSide period={period} data={p2Data} side="right" />
                    : <CompareSideDefault period={period} side="right" />
                )}
            </div>
        </div>
    )
}

export default PlayerCompare;