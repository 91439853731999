import { useFetchHomepage } from "../../hooks/useFetch";
import { H2hData } from "../../types/h2hData";
import HeadToHead from "./HeadToHead";

type H2hProps = {
    h2hData: H2hData | undefined;
    period: string;
    player: string;
}

function H2hTab({ h2hData, period, player }: H2hProps) {
    const { data, loading, error } = useFetchHomepage(period ?? "");
    let filtered = data?.filter((entry) => entry.player.name !== "Mekk" && entry.player.name !== "NoFluxes").sort((a,b) => {
        if (!a.player.conservativeRating) return 1;
        if (!b.player.conservativeRating) return -1;
        return b.player.conservativeRating - a.player.conservativeRating
    });

    if(!player) {
        return <div className="player-h2h-container">Select a player above to see head to head data!</div>
    }

    if (!h2hData || !h2hData.some(h2hEntry => h2hEntry!.sets && h2hEntry!.sets.length > 0 )) {
        return <div className="player-h2h-container">{`${player} has not faced any Top 40 opponents this season.`}</div>;
    }

    const sortedH2hData = h2hData.map((h2h) => {
        let oppRank = "-";
        let numRank = 9999;
        let opp = filtered?.find((p) => p.player.name === h2h?.opponent);
        if (opp?.player.conservativeRating) {
            numRank = (filtered?.findIndex(p => p.player.name === h2h?.opponent) ?? 0) + 1;
            oppRank = "#" + numRank
        } 
        if (opp?.player.name === "Mekk" || opp?.player.name === "NoFluxes") {
            oppRank = "-";
            numRank = 9998;
        }

        return {
            h2h, player, rank: oppRank, numRank
        }
    }).sort((a, b) => a.numRank - b.numRank);

    return (
        <div className="player-h2h-container">
            {
                sortedH2hData.map(o => <HeadToHead h2h={o.h2h!} player={o.player} period={period} rank={o.rank} />)
            }
        </div>
    )
}

export default H2hTab;