import { useCallback, useState } from "react";
import { FaTwitch, FaTwitter } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { HomepageEntry } from "../../types/homepageData";
import { MdPersonSearch } from "react-icons/md";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Autocomplete } from "@mui/material";
import { useAutocompleteOptions } from "../../hooks/useFetch";

type CompareHeaderProps = {
    p1Data: HomepageEntry | undefined;
    p2Data: HomepageEntry | undefined;
    period: string;
}

function CompareHeader({ p1Data, p2Data, period }: CompareHeaderProps) {
    const PLACEHOLDER_AVATAR = "https://storage.googleapis.com/chicago_2022-2/CLM_Logo_Avatar_Placeholder.png";

    return (
        <div className="compare-header">
            <div className="compare-header__player compare-header__player--2">
                 <div className="compare-header__avatar-container">
                    <img src={p2Data?.player.profileImage ?? PLACEHOLDER_AVATAR} alt="" className="compare-header__avatar" />
                </div>
                <CompareHeaderText 
                    data={p2Data} 
                    period={period} 
                    playerNum={2} 
                    otherName={p1Data?.player.name ?? "player1"} 
                />
            </div>
            <div className="compare-header__player compare-header__player--1">
                <CompareHeaderText 
                    data={p1Data} 
                    period={period} 
                    playerNum={1} 
                    otherName={p2Data?.player.name ?? "player2"} 
                />
                <div className="compare-header__avatar-container">
                    <img src={p1Data?.player.profileImage ?? PLACEHOLDER_AVATAR} alt="" className="compare-header__avatar" />
                </div>
            </div>
            <div className="compare-header__vs">
                vs.
            </div>
        </div>
    );
}

function CompareHeaderSelector({ num, otherName, period, onEnter } : { num: number, otherName: string, period: string, onEnter: () => void }) {    
    const options = useAutocompleteOptions(period);
    const navigate = useNavigate();
    const [value, setValue] = useState(null);
    const onChange = useCallback((e: any, newValue: any) => {
        setValue(newValue);

        const onSubmit = (playerName: string) => {
            onEnter();
            if(num===1) navigate(`/stats/compare/${playerName.replace("?", "")}/${otherName}?period=${period}`);
            else navigate(`/stats/compare/${otherName}/${playerName.replace("?", "")}?period=${period}`);
        }
        
        if (!newValue) return;
        if (newValue.label) onSubmit(newValue.label);
        else onSubmit(newValue);
    }, [setValue, navigate, onEnter, period, num, otherName]);

    return (
        <div className="compare-header__form">
            <Autocomplete
                options={options}
                freeSolo
                fullWidth
                value={value}
                onChange={onChange}
                ListboxProps={{
                    style: {
                        maxHeight: "28rem",
                    },
                }}
                renderInput={(params) => {
                    return (
                        <div ref={params.InputProps.ref}>
                            <input
                                type="text"
                                autoFocus
                                {...params.inputProps} 
                                className="compare-header__input"
                                placeholder="Select a player..."
                            />
                        </div>
                    )
                }}
            />
        </div>
    )
}

function CompareHeaderText({ playerNum, data, period, otherName }: { playerNum: number, data: HomepageEntry | undefined, otherName: string, period: string }) {
    const [editName, setEditName] = useState(false);
    const isMobile = useIsMobile();
    
    const toggleEdit = () => {
        setEditName(!editName);
    }

    const longTag = (data?.player.name.length ?? 0) > 7;
    const longName = data?.player.realName && (data?.player.realName.length ?? 0) > 16;


    return (
        data ? 
            <>
            {isMobile && 
                <MdPersonSearch 
                    className={`compare-header__person-search compare-header__person-search--${playerNum}`}
                    onClick={toggleEdit}
                />
            }
            <div className={`compare-header__text compare-header__text--${playerNum}`}>
                {!editName ?
                    <div className="compare-header__top">
                        {playerNum === 1 && <MdPersonSearch className="compare-header__pencil" onClick={toggleEdit} />}
                        <Link className="compare-header__link" to={`/stats/player/${data.player.name}?period=${period}`}>
                            <div className={`compare-header__tag compare-header__tag--${longTag}`}>{data.player.name}</div>
                        </Link>
                        {playerNum === 2 && <MdPersonSearch className={`compare-header__pencil compare-header__pencil--${longTag}`} onClick={toggleEdit} />}
                    </div>
                    :
                    <div className="compare-header__top">
                        {playerNum === 1 && <GiCancel className="compare-header__cancel" onClick={toggleEdit} />}
                        <CompareHeaderSelector num={playerNum} otherName={otherName} period={period} onEnter={toggleEdit} />
                        {playerNum === 2 && <GiCancel className="compare-header__cancel" onClick={toggleEdit} />}
                    </div>
                }
                <div className={`compare-header__name compare-header__name--${longName}`}>{data.player.realName ?? " "}</div>
                <div className={`compare-header__socials compare-header__socials--${playerNum}`}>
                    {data.player.twitterUsername && <>
                        <a href={`https://twitter.com/${data.player.twitterUsername}`} className="compare-header__socials-link" target="_blank" rel="noreferrer">
                            <FaTwitter className="compare-header__socials--icon" />
                            <span className="compare-header__socials--handle">{data.player.twitterUsername}</span>  
                        </a>
                        &nbsp;&nbsp;</>}
                    {data.player.twitchUsername ? 
                    <a href={`https://twitch.tv/${data.player.twitchUsername}`} className="compare-header__socials-link" target="_blank" rel="noreferrer">
                        <FaTwitch className="compare-header__socials--icon" />
                        <span className="compare-header__socials--handle">{data.player.twitchUsername}</span> 
                    </a>: <span className="compare-header__socials--handle">&nbsp;</span>}
                </div>
            </div>
            </>
            : 
            <div className={`compare-header__text compare-header__text--${playerNum}`}>
                <div className="compare-header__top">
                    <CompareHeaderSelector num={playerNum} otherName={otherName} period={period} onEnter={() => {}} />
                </div>
                <div className="compare-header__name">&nbsp;</div>
                <div className={`compare-header__socials compare-header__socials--${playerNum}`}>&nbsp;</div>
            </div>
    );
}

export default CompareHeader;