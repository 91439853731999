import { HomepageEntry } from "../../types/homepageData"
import playerData from "../../_data/player-data.json";
import { AdditionalPlayerData } from "../../types/player";

type CompareSummaryProps = {
    p1Data: HomepageEntry;
    p2Data: HomepageEntry;
}

function CompareSummary({ p1Data, p2Data }: CompareSummaryProps) {

    const p1Rating = p1Data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? p1Data.player.conservativeRating!.toFixed(0) : "-";
    const p2Rating = p2Data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? p2Data.player.conservativeRating!.toFixed(0) : "-";
    const p1Rank = p1Data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? `#${p1Data.rank}` : "N/A";
    const p2Rank = p2Data.player.prEvents >= parseInt(process.env.REACT_APP_PR_EVENT_ELIGIBILITY!) ? `#${p2Data.rank}` : "N/A";

    const additionalPlayerData = new Map(Object.entries(playerData)) as AdditionalPlayerData;

    let p1LastRank = "N/A";
    if(additionalPlayerData.has(p1Data.player.name)) {
        const rank = additionalPlayerData.get(p1Data.player.name)!.lastRank?.toString();
        p1LastRank = rank ? `#${rank}` :  "N/A";
    }

    let p2LastRank = "N/A";
    if(additionalPlayerData.has(p2Data.player.name)) {
        const rank = additionalPlayerData.get(p2Data.player.name)!.lastRank?.toString();
        p2LastRank = rank ? `#${rank}` :  "N/A";
    }

    return (
        <div className="compare-summary">
            <CompareSummaryEntry heading="Current Position" data1={p1Rank} data2={p2Rank} />
            <CompareSummaryEntry heading="Rating" data1={p1Rating} data2={p2Rating} />
            <CompareSummaryEntry heading="Win - Loss" data1={p1Data.player.wins + "-" + p1Data.player.losses} data2={p2Data.player.wins + "-" + p2Data.player.losses} />
            {!!p1Data.player.prWins && !!p2Data.player.prWins && <CompareSummaryEntry heading="PR Wins" data1={p1Data.player.prWins} data2={p2Data.player.prWins} />}
            <CompareSummaryEntry heading="Events" data1={p1Data.player.prEvents} data2={p2Data.player.prEvents} />
            <CompareSummaryEntry heading={`${process.env.REACT_APP_LAST_SEASON_NAME} Rank`} data1={p1LastRank} data2={p2LastRank} />
        </div>
    )
}

function CompareSummaryEntry({ heading, data1, data2 }: { heading: string, data1: string | number, data2: string | number}) {
    return (
        <div className="compare-summary__entry">
            <span className="compare-summary__data compare-summary__data--1">{data1}</span>
            <span className="compare-summary__heading">{heading}</span>
            <span className="compare-summary__data compare-summary__data--2">{data2}</span>
        </div>
    )
}

export default CompareSummary;