import { useState } from "react";
import { useFetchPlayerH2hs } from "../../hooks/useFetch";
import { H2hEntry } from "../../types/h2hData";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";

type H2hMatrixProps = {
    playerNames: string[];
    period: string;
    removePlayer: (tag: string) => void;
    moveUp: (i: number) => void;
    moveDown: (i: number) => void;
    mobile: boolean;
};

type H2hRowProps = {
    player: string;
    period: string;
    playerList: string[];
    col: number;
    curRow: number;
    curCol: number;
    setH: (i: number, j: number) => void;
};

function H2hMatrix({ playerNames, period, removePlayer, moveUp, moveDown, mobile }: H2hMatrixProps) {
    const [curRow, setCurRow] = useState<number>(-1);
    const [curCol, setCurCol] = useState<number>(-1);

    if (playerNames.length === 0) {
        return (
            <div className="top20-container">
                <br />
            </div>
        );
    }

    const setH = (row: number, col: number) => {
        setCurRow(row);
        setCurCol(col);
    };

    return (
        <div className="top20-container" onMouseLeave={() => setH(-1, -1)}>
            <div className="matrix__left-column">
                <div className="matrix__empty-label"></div>
                {playerNames.map((player, i) => (
                    <div className="matrix__left">
                        <div>
                            {(i !== 0 && !mobile) ? <BsArrowUpShort className="matrix__arrow" onClick={() => moveUp(i)} /> : <span className="matrix__arrow" />} 
                            {(i !== playerNames.length-1 && !mobile) && <BsArrowDownShort className="matrix__arrow" onClick={() => moveDown(i)} />}
                        </div>
                        <div
                            onMouseEnter={() => setH(-1, i)}
                            className="matrix__left-label"
                            key={player}
                            onClick={() => removePlayer(player)}
                        >
                            {player}
                        </div>
                    </div>
                ))}
            </div>
            <div className="matrix">
                <div className="matrix__row">
                    {playerNames.map((name, i) => (
                        <div
                            onMouseEnter={() => setH(i, -1)}
                            onClick={() => removePlayer(name)}
                            className="matrix__top-label"
                        >
                            {name}
                        </div>
                    ))}
                </div>
                {playerNames.map((name, i) => (
                    <H2hRow
                        player={name}
                        period={period}
                        playerList={playerNames}
                        key={i}
                        col={i}
                        setH={setH}
                        curRow={curRow}
                        curCol={curCol}
                    />
                ))}
                {playerNames.length > 10 && (
                    <div className="matrix__row">
                        {playerNames.map((name, i) => (
                            <div
                                onMouseEnter={() => setH(i, -1)}
                                onClick={() => removePlayer(name)}
                                className="matrix__top-label"
                            >
                                {name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

function H2hRow({
    player,
    period,
    playerList,
    col,
    curRow,
    curCol,
    setH,
}: H2hRowProps) {
    const { data } = useFetchPlayerH2hs(player, period);

    let editedData: Array<H2hEntry | undefined> = [];

    if (!!data) {
        editedData = playerList.map((tag) => {
            if (tag === player) return undefined;
            return (
                data.find((entry) => entry?.opponent === tag) ??
                ({ opponent: tag, rank: -1, sets: [] } as H2hEntry)
            );
        });
    }

    return data ? (
        <div 
            className="matrix__row" 
        >
            {editedData.map((h2h, row) => (
                <H2hCell
                    h2h={h2h}
                    key={h2h?.opponent}
                    hRow={row === curRow}
                    hCol={col === curCol}
                    setH={() => setH(row, col)}
                />
            ))}
        </div>
    ) : (
        <></>
    );
}

function H2hCell({
    h2h,
    hRow,
    hCol,
    setH,
}: {
    h2h: H2hEntry | undefined;
    hRow: boolean;
    hCol: boolean;
    setH: () => void;
}) {
    if (h2h === undefined) {
        return (
            <div
                className="matrix__cell matrix__cell--empty"
                onMouseEnter={setH}
            ></div>
        );
    }

    /*
    if (hRow && hCol) {
        hRow = false;
        hCol = false;
    }
    */

    const numWins = h2h.sets?.filter((set) => set.setInfo.won).length ?? 0;
    const numLosses = h2h.sets?.filter((set) => !set.setInfo.won).length ?? 0;
    const suffix = Math.abs(numWins - numLosses) >= 3 ? "-big" : "";

    const className =
        numWins > numLosses
            ? "matrix__cell--positive" + suffix
            : numWins < numLosses
            ? "matrix__cell--negative" + suffix
            : numWins !== 0
            ? "matrix__cell--even"
            : "matrix__cell--zero";

    return (
        <div
            onMouseEnter={setH}
            className={
                "matrix__cell " +
                className +
                (hRow ? " matrix__cell--hRow" : "") +
                (hCol ? " matrix__cell--hCol" : "")
            }
        >
            {`${numWins} - ${numLosses}`}
        </div>
    );
}

export default H2hMatrix;
