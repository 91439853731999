import { useCallback, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BsFillGrid3X3GapFill, BsFillPersonFill, BsPeopleFill } from "react-icons/bs";
import { HiOutlineSearch } from "react-icons/hi";
import { MdLeaderboard } from "react-icons/md";
import { ReactComponent as CLMLogo } from "../_assets/icons/clm.svg";
import PeriodSelector from "./PeriodSelector";
import { Autocomplete } from "@mui/material";
import { useAutocompleteOptions } from "../hooks/useFetch";

function TopNav() {
    const navigate = useNavigate();
    const location = useLocation();

    const [queryParams] = useSearchParams();
    const period = queryParams.get("period")!;
    
    let options = useAutocompleteOptions(period ?? process.env.REACT_APP_DEFAULT_PERIOD!);
    const [value, setValue] = useState(null);

    const onChange = useCallback(
        (e: any, newValue: any) => {
            setValue(newValue);

            const onSubmit = (playerName: string) => {
                navigate(
                    `/stats/player/${playerName.replace(
                        "?",
                        ""
                    )}?period=${period}`
                );
            };

            if (!newValue) return;
            if (newValue.label) onSubmit(newValue.label);
            else onSubmit(newValue);
        },
        [setValue, navigate, period]
    );

    const currentPage = location.pathname.split("/")[2] ?? "home";

    return (
        <nav className="menu">
            <ul className="top-nav">
                <MainSiteLink />
                <TopNavItem text="Stats" path={`/stats?period=${period}`} active={currentPage==="home" || currentPage===""} icon={<MdLeaderboard className="top-nav__icon" />} />
                <TopNavItem text="Players" path={`/stats/player/?period=${period}`} active={currentPage==="player"} icon={<BsFillPersonFill className="top-nav__icon" />} />
                <TopNavItem text="Compare" path={`/stats/compare/player1/player2?period=${period}`} active={currentPage==="compare"} icon={<BsPeopleFill className="top-nav__icon" />} />
                <TopNavItem text="H2H" path={`/stats/h2h`} active={currentPage==="h2h"} icon={<BsFillGrid3X3GapFill className="top-nav__icon" />} />
                {/*
                <TopNavItem text="Tournaments" path={`/stats/${period}/tournament`} icon={<FaTrophy className="top-nav__icon" />} />
                */}
            </ul>

            <form action="#" className="search">
                <Autocomplete
                    options={options}
                    freeSolo
                    value={value}
                    onChange={onChange}
                    ListboxProps={{
                        style: {
                            maxHeight: "28rem",
                        },
                    }}
                    renderInput={(params) => {
                        return (
                            <div className="search--container" ref={params.InputProps.ref}>
                                <input 
                                    type="text" 
                                    {...params.inputProps}
                                    className="search__input" 
                                    placeholder="Search players..." 
                                />
                                <HiOutlineSearch className="search__icon" />
                            </div>
                        );
                    }}
                />    
            </form>
            <PeriodSelector />
        </nav>
    );
}

function TopNavItem({ text, path, icon, active }: { text: string, path: string, active: boolean, icon?: JSX.Element }) {
    return (
        <Link className="top-nav__anchor" to={path}>
            <li className={"top-nav__item top-nav__item--" + active}>
                <div className="top-nav__link">
                    {icon}
                    <span className="top-nav__text">{text}</span>
                </div>
            </li>
        </Link>
    );
}

function MainSiteLink() {
    return (
        <a className="top-nav__anchor" href="http://chicagomelee.com">
            <li className={"top-nav__item"}>
                <div className="top-nav__link">
                    <CLMLogo className="top-nav__logo" />
                    <span className="top-nav__text">CLM</span>
                </div>
            </li>
        </a>
    )
}

export default TopNav;